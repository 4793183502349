import { formatCurrency } from "@/utils/helper";
import { RouterOutputs } from "@/utils/trpc";
import { BarChart } from "@tremor/react";
import { FC } from "react";
import NotFound from "../utils/notfound";

type Props = {
  data?: RouterOutputs["reports"]["barChart"]["cashFlow"];
  currency: string;
};

const months = [
  { name: "Jan", value: 0 },
  { name: "Feb", value: 1 },
  { name: "Mar", value: 2 },
  { name: "Apr", value: 3 },
  { name: "May", value: 4 },
  { name: "Jun", value: 5 },
  { name: "Jul", value: 6 },
  { name: "Aug", value: 7 },
  { name: "Sep", value: 8 },
  { name: "Oct", value: 9 },
  { name: "Nov", value: 10 },
  { name: "Dec", value: 11 },
];

const CashFlow: FC<Props> = ({ data, currency }) => {
  if (!data) return <NotFound title="Data" />;

  return (
    <div className="">
      <span className="badge badge-primary">Cashflow</span>
      <BarChart
        data={months.map((m) => ({
          debit: data.debits[m.value],
          credit: data.credits[m.value],
          name: m.name,
        }))}
        index="name"
        categories={["credit", "debit"]}
        colors={["green", "rose"]}
        valueFormatter={(v) => formatCurrency(v, currency)}
      />
    </div>
  );
};

export default CashFlow;

import Drawer from "@/components/drawer/Drawer";
import ViewJSON from "@/components/ViewJSON";
import Categorization from "@/pages/accounts/components/Categorization";
import { formatCurrency } from "@/utils/helper";
import { api } from "@/utils/trpc";
import { VscJson } from "react-icons/vsc";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import MerchantCoverage from "./components/MerchantCompletion";

type Props = {};

const AccountPage = (props: Props) => {
  const { id } = useParams<{ id: string }>();

  const { data } = api.account.get.useQuery(id!);
  const nav = useNavigate();

  const [params] = useSearchParams();
  const json = params.get("json");

  if (!data) return;

  console.log(data);
  return (
    <div className="grid gap-10">
      <div className="card lg:card-side bg-base-100 shadow-xl">
        <div className="card-body gap-6">
          <div className=" flex gap-2 items-center">
            <h3>@{data.bankName}</h3>
            <figure className="w-10">
              <img src={data.bankLogo!} alt="country" />
            </figure>
          </div>
          <div className="flex items-center gap-2 w-fit">
            <p className="font-normal">Source</p>
            <p className="badge badge-neutral">{data.source}</p>
          </div>
          <button
            onClick={() => nav("?json=true")}
            className="btn w-fit btn-sm btn-primary"
          >
            <VscJson /> View JSON
          </button>
        </div>
        <div className="card-body">
          <div className="flex items-center gap-2 w-fit">
            <p className="font-normal">Total Balance:</p>
            <p className="badge badge-neutral">
              {formatCurrency(data.balance, data.currency)}
            </p>
          </div>
          {data.IBAN && (
            <div className="flex items-center gap-2 w-fit">
              <p className="font-normal">IBAN</p>
              <p className="badge badge-neutral">{data.IBAN}</p>
            </div>
          )}
          {data.BBAN && (
            <div className="flex items-center gap-2 w-fit">
              <p className="font-normal">BBAN:</p>
              <p className="badge badge-neutral">{data.BBAN}</p>
            </div>
          )}
          <div className="flex items-center gap-2 w-fit">
            <p className="font-normal">SWIFT:</p>
            <p className="badge badge-neutral">{data.SWIFT}</p>
          </div>
        </div>
      </div>

      <div className="flex gap-10 items-center">
        <Categorization data={data.metrics} />
        <MerchantCoverage data={data.metrics} />
      </div>

      <Drawer
        isOpen={!!json}
        onClose={() => nav(`/account/${data.id}`)}
        title="Account "
      >
        <ViewJSON json={data.json} defaultOpen />
      </Drawer>
    </div>
  );
};

export default AccountPage;

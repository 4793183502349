import InViewTrigger from "@/components/InViewTrigger";
import { useOrganization } from "@/context/OrganizationContext";
import { accountingStatuses } from "@/lib";
import Checkbox from "@/ui/Checkbox";
import SelectDate from "@/ui/SelectDate";
import Rows from "@/ui/skeletons/Rows";
import { formatCurrency, formatLabel, saveFile } from "@/utils/helper";
import { api, RouterOutputs } from "@/utils/trpc";
import format from "date-fns/format";
import { useAtom } from "jotai";
import { json2csv } from "json-2-csv";
import { FC, useMemo } from "react";
import toast from "react-hot-toast";
import { HiDownload } from "react-icons/hi";
import { RiRefreshLine } from "react-icons/ri";
import { formatCSV } from "./helper";
import { filterAtom } from "./store/filters";
import { selectionAtom } from "./store/Selection";
import TransactionRow from "./TransactionRow";

type Props = {
  showAccountingOverview?: boolean;
};

export type TransactionHistory =
  RouterOutputs["transaction"]["list"]["data"][number];

const Transactions: FC<Props> = ({ showAccountingOverview }) => {
  const { organizationId, organization } = useOrganization<true>();
  const [selection, setSelection] = useAtom(selectionAtom);
  const [filters, setFilters] = useAtom(filterAtom);

  const { data: overview } = api.transaction.accountingOverview.useQuery(
    organizationId,
    { enabled: showAccountingOverview }
  );

  const {
    data = { pages: [] },
    isLoading,
    isRefetching,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = api.transaction.list.useInfiniteQuery(
    { organizationId, ...filters },
    { getNextPageParam: (p) => p.nextCursor }
  );

  const selectionArray = useMemo(() => Object.values(selection), [selection]);

  const transactions = useMemo(() => data.pages.flatMap((p) => p.data), [data]);

  const handleRefetch = async () => {
    await refetch();
    toast.success("Refetch successful!");
  };

  const handleDownload = async () => {
    if (!selectionArray.length) return;

    const csv = json2csv(formatCSV(selectionArray));
    const blob = new Blob([csv], { type: "text/csv" });

    saveFile(
      blob,
      `Dynt_Transactions_${format(new Date(), "dd-MMM-yyyy")}.csv`
    );
    setSelection({});
  };

  const accountingOverview = useMemo(() => {
    if (!overview) return [];
    return accountingStatuses
      .map((s) => ({ status: s, count: 0, amount: 0, ...overview[s] }))
      .sort((a, b) => b.count - a.count);
  }, [overview]);

  const isAllSelected = selectionArray.length === transactions.length;

  return (
    <div className="">
      <div className="flex my-6 justify-between items-center">
        <div className="flex gap-4 items-center">
          {!!selectionArray.length && (
            <button className="flex text-primary text-sm px-4 gap-4">
              <Checkbox
                checked={isAllSelected}
                onChange={() => {
                  if (isAllSelected) return setSelection({});
                  setSelection(
                    Object.fromEntries(transactions.map((t) => [t.id, t]))
                  );
                }}
              />
              Select all
            </button>
          )}
          {showAccountingOverview && (
            <div className="flex items-center gap-2">
              {" "}
              <button className={`btn btn-sm btn-outline btn-ghost `}>
                <p className="capitalize flex items-center gap-2">
                  All
                  <p>
                    {accountingOverview.reduce((acc, el) => acc + el.count, 0)}
                  </p>
                </p>
              </button>
              {accountingOverview.map((s) => (
                <button className={`btn btn-sm btn-outline btn-ghost `}>
                  <p className="capitalize flex items-center gap-2">
                    {formatLabel(s.status)}
                    <span className="text-xs">
                      ({formatCurrency(s.amount, organization.defaultCurrency)})
                    </span>
                    <p>{s.count}</p>
                  </p>
                </button>
              ))}
            </div>
          )}
        </div>

        <div className="flex justify-end items-center gap-2">
          <SelectDate
            value={filters.period}
            onChange={(e) => setFilters((p) => ({ ...p, period: e }))}
          />
          {/* <Link
            to={"?filters"}
            onClick={() => setShowFilters(true)}
            className="btn btn-primary h-12 btn-sm md:btn-md"
          >
            <MdFilterListAlt />
          </Link> */}
          <button
            className="btn btn-secondary justify-self-end btn-outline btn-sm"
            onClick={handleRefetch}
          >
            <RiRefreshLine
              size={20}
              className={isRefetching ? "animate-spin" : ""}
            />
          </button>
          <div
            className="tooltip tooltip-left"
            data-tip={
              selectionArray.length
                ? `Download ${selectionArray.length} Transactions`
                : "Please select items to download"
            }
          >
            <button
              className={`btn btn-sm ${
                selectionArray.length ? " btn-primary" : "pointer-events-none"
              } `}
              onClick={handleDownload}
            >
              <HiDownload />
            </button>
          </div>
        </div>
      </div>
      <table className="table bg-base-200">
        <thead>
          <tr>
            <th></th>
            <th>Date</th>
            <td>To/From</td>
            <td>Account</td>
            <th>Status</th>
            <th>Amount</th>
            {showAccountingOverview ? (
              <th className="text-center">Accounting Status</th>
            ) : (
              <th>Category</th>
            )}
          </tr>
        </thead>

        <tbody>
          {transactions.map((transaction, index, { length }) => (
            <TransactionRow
              {...{ transaction, showAccountingOverview, index }}
              key={transaction.id}
            />
          ))}
        </tbody>
      </table>
      <Rows isLoading={isLoading} />

      <InViewTrigger
        onInView={fetchNextPage}
        children={<Rows count={1} isLoading={isFetchingNextPage} />}
      />
    </div>
  );
};

export default Transactions;
